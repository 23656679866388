<template>
  <validation-observer ref="simpleRules">
    <b-card-code title="Report Todo List">
      Filter
      <b-row class="match-height mb-2">
        <b-col md="6">
          <b-form-group
            label="Date Range"
            label-for="Role"
          >
            <flat-pickr
              v-model="rangeDate"
              placeholder="Input date"
              class="form-control"
              :config="{ mode: 'range'}"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="User"
            label-for="User"
          >
            <v-select
              v-model="selectedUser"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              multiple
              placeholder="Select User"
              :options="optionsUser"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Area"
            label-for="Area"
          >
            <v-select
              v-model="selectedArea"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              multiple
              placeholder="Select Area"
              :options="optionsArea"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Work"
            label-for="Work"
          >
            <v-select
              v-model="selectedArea"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              multiple
              placeholder="Select Work"
              :options="optionsArea"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Status"
            label-for="Status"
          >
            <v-select
              v-model="StatusWork"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              multiple
              placeholder="Select Area"
              :options="['Approved', 'Not Approve']"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="dataRows"
        :is-loading="loading"
        style-class="vgt-table striped"
        :search-options="{
          enabled: false,
          externalQuery: searchTerm }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          position: 'bottom'
        }"
      >
        <div
          slot="emptystate"
          align="center"
        >
          No Data
        </div>
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Status -->
          <span
            v-if="props.column.field === 'Status'"
            :title="props.row.Komentar"
          >
            <b-badge :variant="statusVariant(props.row.Status)">
              {{ props.row.Status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'Action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none nopad"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="openDetail(props.row.selectedItem)">
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-50"
                  />
                  <span>View Detail</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['5','10','20','50']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ totalPage }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="totalPage"
                :per-page="totalRow"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>

      <b-modal
        id="modal-center"
        ref="modal"
        v-model="modalDetail"
        centered
        ok-only
        title="Detail date - worker"
        ok-title="Ok"
        size="lg"
        @ok="validationForm"
      >

        <b-row class="match-height">
          <b-col md="4">
            <validation-provider
              #default="{ errors }"
              name="Worker Name"
              rules="required"
            >
              <b-form-group
                label="Worker Name"
                label-for="Work Name"
              >
                <b-form-input
                  id="Worker Name"
                  v-model="name"
                  placeholder="Input Work Name"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="4">
            <validation-provider
              #default="{ errors }"
              name="Date"
              rules="required"
            >
              <b-form-group
                label="Date"
                label-for="Work Name"
              >
                <b-form-input
                  id="Date"
                  v-model="name"
                  placeholder="Input Work Name"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="4">
            <validation-provider
              #default="{ errors }"
              name="Area"
              rules="required"
            >
              <b-form-group
                label="Area"
                label-for="Area"
              >
                <b-form-input
                  id="Worker Name"
                  v-model="name"
                  placeholder="Input Area"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <!-- end row -->

        <app-collapse>
          <app-collapse-item title="Collapse Item 3">
            <b-row class="match-height">
              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Work"
                  rules="required"
                >
                  <b-form-group
                    label="Work"
                    label-for="Work"
                  >
                    <b-form-input
                      id="Worker Name"
                      v-model="name"
                      placeholder="Input Work Name"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Checklist Time"
                  rules="required"
                >
                  <b-form-group
                    label="Checklist Time"
                    label-for="Checklist Time"
                  >
                    <b-form-input
                      id="Checklist Time"
                      v-model="name"
                      placeholder="Input Work Name"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Update Time"
                  rules="required"
                >
                  <b-form-group
                    label="Update Time"
                    label-for="Update Time"
                  >
                    <b-form-input
                      id="Update Time"
                      v-model="name"
                      placeholder="Input Area"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <!-- end row -->
            <b-row class="match-height">
              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Photo Before"
                  rules="required"
                >
                  <b-form-group
                    label="Photo Before"
                    label-for="Photo Before"
                  >
                    <b-form-input
                      id="Worker Name"
                      v-model="name"
                      placeholder="Input Work Name"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Photo Process"
                  rules="required"
                >
                  <b-form-group
                    label="Photo Process"
                    label-for="Photo Process"
                  >
                    <b-form-input
                      id="Photo Process"
                      v-model="name"
                      placeholder="Input Work Name"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Photo After"
                  rules="required"
                >
                  <b-form-group
                    label="Photo After"
                    label-for="Photo After"
                  >
                    <b-form-input
                      id="Update Time"
                      v-model="name"
                      placeholder="Input Area"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <!-- end row -->
          </app-collapse-item>
        </app-collapse>
      </b-modal>
    </b-card-code>
  </validation-observer>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BLink,
  VBTooltip,
  VBModal,
  BRow,
  BCol,
  BTable,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from '@axios'
import dayjs from 'dayjs'
import store from '@/store/index'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BLink,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    VBModal,
    VBTooltip,
    flatPickr,
    BRow,
    BCol,
    vSelect,
    BTable,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  data() {
    return {
      pageTitle: '',
      loading: true,
      pageLength: 10,
      dir: false,
      totalPage: 0,
      totalRow: 0,
      page: 0,
      fromPage: 0,
      toPage: 0,
      rangeDate: null,
      selectedItem: [],
      optionsArea: [],
      optionsUser: [],
      selectedUser: [],
      selectedArea: [],
      StatusWork: '',
      modalDetail: false,
      timePicker: null,
      // codeBasic,
      columns: [
        {
          label: 'Date',
          field: 'Date',
        },
        {
          label: 'Worker Name',
          field: 'WorkerName',
        },
        {
          label: 'Area',
          field: 'Area',
        },
        {
          label: 'Checklist Time',
          field: 'ChecklistTime',
        },
        {
          label: 'Status',
          field: 'Status',
        },
        {
          label: 'Action',
          field: 'Action',
        },
      ],
      dataRows: [
        {
          Date: '',
          Komentar: [],
        },
      ],
      searchTerm: '',
      Status: [
        {
          1: 'Procces',
          2: 'Proses',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Procces: 'light-primary',
        Approved: 'light-success',
        'Not Approve': 'light-danger',
        Resigned: 'light-warning',
        Process: 'light-info',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
  },
  watch: {
    $route() {
      this.getDisposisi()
    },
    pageLength() {
      this.getDisposisi()
    },
  },
  created() {
    // if (
    //   store.state.userData.permission.find(e => e === 'SIAP.Disposition.Code.Z')
    // ) {
    //   this.authorize = true
    // } else {
    //   this.authorize = false
    // }
  },
  mounted() {
    this.getDisposisi()
    this.getArea()
    this.getUser()
  },
  methods: {
    openDetail(e) {
      this.modalDetail = !this.modalDetail
      this.selectedItem = e
    },
    async getArea() {
      const { data } = await axios.get('/area')
      this.optionsArea = data.data.map(e => ({
        value: e.id,
        text: e.area,
      }))
    },
    async getUser() {
      const { data } = await axios.get('/user')
      this.optionsUser = data.data.map(e => ({
        value: e.id,
        text: e.nama,
      }))
    },
    async getDisposisi(val) {
      const { data } = await axios.get('/todo', {
        params: {
          // page: this.page === 0 ? null : this.page,
        },
      })
      this.totalPage = data.total
      this.totalRow = data.per_page
      this.fromPage = data.from
      this.toPage = data.to
      if (data.total !== 0) {
        this.dataRows = data.data.map(e => ({
          selectedItem: e,
          Date: e.date || '-',
          WorkerName: e.worker.nama,
          Area: e.work[0].area.area,
          Name: e.nama || '-',
          ChecklistTime:
            e.work[0].checklist_time !== null
              ? dayjs(e.work[0].checklist_time).format('HH:mm')
              : '-',
          Status: e.is_approved !== 0 ? 'Approved' : 'Not Approve',
          Action: '',
        }))
      } else {
        this.dataRows = []
      }
      this.loading = false
      //   .catch(error => {
      //     console.log(error)
      //   })
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.nopad {
  padding: 0;
}
.link-no {
  border-bottom: solid 1px #c5c5c5;
  padding-bottom: 3px;
  cursor: pointer;
}
</style>
